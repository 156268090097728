<template>
  <aside class="sidebar-block">
    <div class="sidebar-block__header d-block d-lg-none">
      <hr />

      <h5 class="sidebar-block__title" v-t="'account.sidebar.my-account'">
        My account
      </h5>
    </div>

    <div class="sidebar-block__body">
      <nav class="side-nav side-nav--dashoard" role="navigation" aria-label="">
        <ul
          class="list-group list-group-flush side-nav__list side-nav__list--styled text-sm"
        >
          <li
            class="list-group-item side-nav__item side-nav__item--styled"
            :class="{
              'is-active': $route.matched.some(
                ({ name }) => name === 'Account Dashboard'
              )
            }"
          >
            <router-link
              :to="{ name: 'Account Dashboard' }"
              class="side-nav__link side-nav__link--styled"
            >
              <i class="icon icon-person_outline mr-2"></i>
              {{ $t("account.sidemenu.account") }}
            </router-link>
          </li>
          <li
            class="list-group-item side-nav__item side-nav__item--styled"
            :class="{
              'is-active':
                $route.matched.some(({ name }) => name === 'Orders') ||
                $route.matched.some(({ name }) => name === 'Order')
            }"
          >
            <router-link
              :to="{ name: 'Orders' }"
              class="side-nav__link side-nav__link--styled"
            >
              <i class="icon icon-shopping_cart mr-2"></i>
              {{ $t("account.sidemenu.orders") }}
            </router-link>
          </li>
          <li
            class="list-group-item side-nav__item side-nav__item--styled"
            :class="{
              'is-active':
                $route.matched.some(
                  ({ name }) => name === 'Processed Orders'
                ) ||
                $route.matched.some(({ name }) => name === 'Processed Order'),
            }"
          >
            <router-link
              :to="{ name: 'Processed Orders' }"
              class="side-nav__link side-nav__link--styled"
            >
              <i class="icon icon-shopping_cart mr-2"></i>
              {{ $t("account.sidemenu.processed-orders") }}
            </router-link>
          </li>

          <!-- <li
            class="list-group-item side-nav__item side-nav__item--styled"
            :class="{
              'is-active': $route.matched.some(
                ({ name }) => name === 'Address book'
              )
            }"
          >
            <router-link
              to="/account/address-book"
              class="side-nav__link side-nav__link--styled"
            >
              <i class="icon icon-home mr-2"></i>
              {{ $t("account.sidemenu.address-book") }}
            </router-link>
          </li> -->
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  methods: {
    subIsActive(input) {
      //console.log(input);
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
