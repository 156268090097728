<template>
  <div class="box order">
    <div class="box__header">
      <div class="order__meta">
        <div class="order__date text-sm mr-auto mr-md-5">
          {{ $d(order.date, "short") }}
        </div>
        <div class="order__nr mr-auto mr-md-0">
          {{ $t("processed-order.order-number") }} {{ order.no }}
        </div>
      </div>
      <a
        href="#"
        class="btn btn-sm btn-empty btn-empty-primary ml-auto"
        title=""
        @click="downloadInvoice"
      >
        <span v-t="'processed-order.download-invoice'"> Download invoice </span>
        <i class="icon icon-description"></i>
      </a>
    </div>
    <router-link
      :order="order"
      :to="{
        name: 'Processed Order',
        params: {
          order: order,
          orderSlug: order.no,
        },
      }"
      class="box__body order__body"
    >
      <div class="box__content order__content d-flex align-items-center">
        <div class="order__title text-subtitle">
          <span v-if="order.reference">
            {{ order.reference }}
          </span>
        </div>
        <div class="order__status">
          <div>
            <div
              class="order__status-label text-xs"
              v-t="'processed-order.status'"
            >
              Status
            </div>
            <div class="order__status-status text-sm text-subtitle">
              {{ order.status }}
            </div>
          </div>
          <div>
            <div
              class="order__status-label text-xs"
              v-t="'processed-order.delivery-date'"
            >
              Expected delivery
            </div>
            <div class="order__status-status text-sm text-subtitle">
              {{ $d(order.latestDeliveryDate, "short") }}
            </div>
          </div>
        </div>
        <div class="order__price price">
          <ProductPrice :price="order.priceIncl" type="xl"></ProductPrice>
        </div>
        <div class="order__actions ml-auto">
          <i class="icon icon-keyboard_arrow_right"></i>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import ProductPrice from "@/components/Products/ProductPrice.vue";
import { mapActions } from "vuex";
export default {
  components: {
    ProductPrice,
  },
  props: {
    order: { type: Object },
  },
  computed: {},
  methods: {
    ...mapActions(["redirectToProcessedOrderInvoice"]),
    downloadInvoice() {
      this.redirectToProcessedOrderInvoice({ no: this.order.no });
    },
  },
};
</script>

<style lang="css" scoped></style>
