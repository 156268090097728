<template>
  <div class="box order">
    <div class="box__header">
      <div class="order__meta">
        <div class="order__date text-sm mr-auto mr-md-5">
          {{ $d(order.order_date, "short") }}
        </div>
        <div class="order__nr mr-auto mr-md-0">
          {{ $t("order.order-number") }} {{ order.order_number }}
        </div>
      </div>
    </div>
    <router-link
      :order="order"
      :to="{
        name: 'Order',
        params: {
          order: order,
          orderSlug: order.order_number,
        },
      }"
      class="box__body order__body"
    >
      <div class="box__content order__content d-flex align-items-center">
        <div class="order__title text-subtitle">
          <span v-if="order.reference">
            {{ order.reference }}
          </span>
        </div>
        <div class="order__status">
          <div>
            <div class="order__status-label text-xs" v-t="'order.status'">
              Status
            </div>
            <div class="order__status-status text-sm text-subtitle">
              {{ order.status }}
            </div>
          </div>
          <!-- <div>
            <div class="order__status-label text-xs">
              Expected delivery
            </div>
            <div class="order__status-status text-sm text-subtitle">
              18-09-202
            </div>
          </div> -->
        </div>
        <div class="order__price price">
          <ProductPrice
            :price="order.total.grand_total"
            type="xl"
          ></ProductPrice>
        </div>
        <div class="order__actions ml-auto">
          <i class="icon icon-keyboard_arrow_right"></i>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import ProductPrice from "@/components/Products/ProductPrice.vue";
export default {
  components: {
    ProductPrice,
  },
  props: {
    order: { type: Object },
  },
};
</script>

<style lang="css" scoped></style>
