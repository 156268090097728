<template>
  <section class="section shop">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 order-2 order-lg-1">
          <Sidebar></Sidebar>
        </div>

        <div class="col-lg-9 order-1 order-lg-2 mb-6 mb-lg-0">
          <section class="section mb-6">
            <div class="section__header mb-4">
              <h1
                class="section__title h3"
                v-t="'account.processed-orders.title'"
              >
                Processed Orders
              </h1>
            </div>

            <div class="section__body">
              <div class="form-fields">
                <div class="form-group">
                  <div class="search-input">
                    <input
                      type="text"
                      class="
                        form-control form-control-lg
                        form-control--shadow
                        search-input__field
                      "
                      id="input-search"
                      aria-describedby="search"
                      :placeholder="
                        $t('account.processed-orders.search-placeholder')
                      "
                      v-model="searchValue"
                      @input="searchChanged"
                    />

                    <button
                      class="
                        btn btn-secondary btn-icon-only
                        search-input__button
                      "
                    >
                      <i class="icon icon-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- <div
              class="section__meta search-results__meta d-flex justify-content-between align-items-center text-sm w-100"
            >
              <div class="d-flex align-items-center flex-shrink-0 ml-auto">
                <label for="sort-by" class="mb-0 mr-3">Sort by:</label>
                <div class="custom-control custom-selectbox">
                  <select class="form-control form-control-sm">
                    <option selected>Relevance</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </div> -->

          <hr class="mt-3 mb-5" />
          <template
            v-if="
              processedOrders &&
              processedOrders.length > 0 &&
              !isFetchingProcessedOrders
            "
          >
            <section class="section mb-6">
              <div class="section__body">
                <ProcessedOrder
                  v-for="(order, i) in toBeShown"
                  :key="i"
                  :order="order"
                >
                </ProcessedOrder>
              </div>

              <div class="actions mt-5 text-center">
                <button
                  class="btn btn-secondary"
                  @click="nextPage"
                  :disabled="currentPage == totalPages"
                  :class="
                    currentPage == totalPages ? 'd-none' : 'd-inline-flex'
                  "
                  v-t="'account.processed-orders.show-more'"
                >
                  Show More
                  <span class="icon icon-keyboard_arrow_down"></span>
                </button>
              </div>
            </section>
          </template>

          <template v-else-if="isFetchingProcessedOrders">
            <div class="d-none d-lg-block">
              <ContentLoader viewBox="0 0 1180 1130" height="1130" width="1180">
                <rect x="0" y="0" width="420" height="33px" />

                <rect x="0" y="50" width="900" height="20" />
                <rect x="0" y="76" width="800" height="20" />
              </ContentLoader>
            </div>

            <div class="d-block d-lg-none">
              <ContentLoader viewBox="0 0 700 1130" height="1130" width="700">
                <rect x="0" y="0" width="220" height="33px" />

                <rect x="0" y="50" width="350" height="20" />
                <rect x="0" y="76" width="300" height="20" />
              </ContentLoader>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { mapGetters, mapActions } from "vuex";
import ProcessedOrder from "../../components/Orders/ProcessedOrder.vue";
import Sidebar from "./Sidebar.vue";

export default {
  components: {
    ProcessedOrder,
    Sidebar,
    ContentLoader,
  },
  data: function () {
    return {
      searchValue: "",
      currentPage: 1,
    };
  },
  created() {
    this.retrieveProcessedOrders().catch((error) => {
      if (error === 400) this.$router.push({ name: "Login" });
      if (error === 401) this.$router.push({ name: "Login" });
    });
  },
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "authUser",
      "processedOrders",
      "isFetchingProcessedOrders",
    ]),
    toBeShown() {
      return this.processedOrders.slice(0, this.currentPage * 12);
    },
    totalPages() {
      return Math.ceil(this.processedOrders.length / 12);
    },
  },
  methods: {
    ...mapActions(["retrieveProcessedOrders"]),
    searchChanged() {
      this.currentPage = 1;

      this.retrieveProcessedOrders({
        search: this.searchValue,
      }).catch((error) => {
        if (error === 400) location.reload();
        if (error === 401) location.reload();
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      this.currentPage = this.currentPage - 1 || 1;
    },
  },
};
</script>

<style lang="css" scoped></style>
