<template lang="html">
  <div class="product-item__price price" :class="type == 'none' ? 'mb-0' : ''">
    <!-- Show accessible price to screen readers. -->
    <!-- <span class="sr-only">€ {{ finalPrice() }} </span> -->

    <!-- Hide fancy price from screen readers. -->
    <!-- <span class="price__currency text-sm" aria-hidden="true">€&nbsp;</span>
    <span class="price__int text-xl" aria-hidden="true">
      {{ finalPrice() }}
    </span> -->

    <i18n-n :value="finalPrice()" format="currency">
      <template v-slot:currency="">
        <template v-if="type != 'none'">
          <span class="price__currency text-sm">{{ getCurrency() }}&nbsp;</span>
        </template>
        <template v-else>{{ getCurrency() }}&nbsp;</template>
      </template>
      <template v-slot:integer="{ integer }">
        <span :class="priceStyle">{{ integer }}</span>
      </template>
      <template v-slot:group="{ group }">
        <span :class="priceStyle">{{ group }}</span>
      </template>
      <template v-slot:decimal="{ decimal }">
        <template v-if="type != 'none'">
  <span class="price__currency text-sm d-none">{{ decimal }}</span>
</template>
        <template v-else>{{ decimal }}</template>
      </template>
      <template v-slot:fraction="{ fraction }">
        <template v-if="type != 'none'">
  <span class="price__currency text-sm" :class="{ 'd-none': fraction == '00' }"
    >&nbsp;{{ fraction }}</span
  >
</template>
        <template v-else>{{ fraction }}</template>
      </template>
    </i18n-n>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: Object,
    },
    type: {
      type: String,
    },
    cart_item_quantity: {
      type: Number,
    },
  },
  data: function () {
    return {
      cart_item_price: 0,
      priceStyle: "",
    };
  },
  created() {
    if (this.type != "none") {
      this.priceStyle = "text-xl price__int";
    }
  },
  methods: {
    finalPrice: function () {
      if (this.cart_item_quantity) {
        return this.cart_item_quantity * this.price.value;
      } else {
        return this.price.value;
      }
    },
    getCurrency: function () {
      return this.price.currency;
    },
  },
};
</script>

<style lang="css" scoped></style>
